import * as React from "react";
import styles from "./pictures.module.scss";
import { SplitText } from "@utils/gsap/SplitText";
import Image from "next/image";
import { gsap } from "gsap";
import Image1 from "../../../public/assets/images/group-1.png";
import Image2 from "../../../public/assets/images/group-2.png";
import Image3 from "../../../public/assets/images/group-3.png";
import Image4 from "../../../public/assets/images/group-4.png";
import Image5 from "../../../public/assets/images/group-5.png";

type Props = {};

const Pictures: React.FC<Props> = (props) => {
  let textRef = React.useRef<HTMLParagraphElement | null>(null);
  let sectionRef = React.useRef<HTMLElement | null>(null);
  let bottomRef = React.useRef<HTMLDivElement | null>(null);
  let imageRefs = React.useRef<Array<HTMLDivElement | null>>([]);

  React.useEffect(() => {
    let currentTextRef = textRef.current as gsap.TweenTarget;
    let currentButtonRef = bottomRef.current as gsap.TweenTarget;
    let currentSectionRef = sectionRef.current;
    let currentImageRefs = imageRefs.current;

    let mySplitText = new SplitText(currentButtonRef, { type: "words" });

    let characters = mySplitText.words;

    gsap.defaults({ ease: "Power4.out" });

    let tl1 = gsap.timeline({
      scrollTrigger: {
        trigger: currentSectionRef,
        start: "top 60%",
        // markers: process.env.NODE_ENV === "development" ? true : false
      },
    });

    let tl2 = gsap.timeline({
      scrollTrigger: {
        trigger: currentImageRefs,
        start: "top 50%",
        // markers: process.env.NODE_ENV === "development" ? true : false
      },
    });

    tl1.from(currentTextRef, { duration: 0.6, autoAlpha: 0, y: 40 }).from(
      characters,
      {
        duration: 0.8,
        y: 200,
        autoAlpha: 0,
        ease: "Power4.out",
        stagger: 0.07,
      },
      "-=0.2"
    );

    tl2.staggerTo(
      currentImageRefs,
      1,
      {
        clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)",
        autoAlpha: 1,
        delay: 0.2,
        ease: "expo.out",
      },
      0.4
    );

    return () => {};
  }, []);

  return (
    <section className={styles["pictures"]} ref={sectionRef}>
      <div className={styles["pictures__container"]}>
        <p className={styles["pictures__text__tiny"]} ref={textRef}>
          Inawo helps you create
        </p>

        <div className={styles["pictures__hidden"]}>
          <div className={styles["pictures__text"]} ref={bottomRef}>
            The seamless path to an elegant wedding
          </div>
        </div>

        <div className={styles["pictures__collection"]}>
          <div
            className={`${styles["pictures__collection__item_1"]} ${styles["pictures__collection__item"]}`}
            ref={(element) => {
              imageRefs.current[0] = element;
            }}
          >
            <Image
              src={Image1}
              alt="wedding"
              placeholder="blur"
              width={362}
              height={428}
              layout="responsive"
            />
          </div>
          <div
            className={`${styles["pictures__collection__item_2"]} ${styles["pictures__collection__item"]}`}
            ref={(element) => {
              imageRefs.current[1] = element;
            }}
          >
            <Image
              src={Image2}
              alt="wedding"
              placeholder="blur"
              width={362}
              height={428}
              layout="responsive"
            />
          </div>
          <div
            className={`${styles["pictures__collection__item_3"]} ${styles["pictures__collection__item"]}`}
            ref={(element) => {
              imageRefs.current[2] = element;
            }}
          >
            <Image
              src={Image3}
              alt="wedding"
              placeholder="blur"
              width={338}
              height={588}
              layout="responsive"
            />
          </div>
          <div
            className={`${styles["pictures__collection__item_4"]} ${styles["pictures__collection__item"]}`}
            ref={(element) => {
              imageRefs.current[3] = element;
            }}
          >
            <Image
              src={Image4}
              alt="wedding"
              placeholder="blur"
              width={400}
              height={382}
              layout="responsive"
            />
          </div>
          <div
            className={`${styles["pictures__collection__item_5"]} ${styles["pictures__collection__item"]}`}
            ref={(element) => {
              imageRefs.current[4] = element;
            }}
          >
            <Image
              src={Image5}
              alt="wedding"
              placeholder="blur"
              width={400}
              height={382}
              layout="responsive"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Pictures;
