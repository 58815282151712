import * as React from "react";
import styles from "./sections.module.scss";
import BottomSection from "./BottomSection";
import TopSection from "./TopSection";

type Props = {};

const Sections: React.FC<Props> = (props) => {
  return (
    <section className={styles["sections"]}>
      <TopSection />
      <BottomSection />
    </section>
  );
};

export default Sections;
