import * as React from "react";
import styles from "./top.module.scss";
import SingleSection from "../SingleSection";
import { gsap } from "gsap";
import Image from "next/image";
import Model from "../../../../public/assets/images/person.png";
import Invitations from "../../../../public/assets/images/save-date.png";
import MyPlan from "../../../../public/assets/images/my-plan.png";

type Props = {
  left?: React.ReactNode;
  right?: React.ReactNode;
};

const TopSection: React.FC<Props> = (props) => {
  let bottomRef = React.useRef<Array<HTMLDivElement | null>>([]);
  let topRef = React.useRef<Array<HTMLDivElement | null>>([]);

  React.useEffect(() => {
    let currentTopRef = topRef.current;
    let currentBottomRef = bottomRef.current;

    gsap.defaults({ ease: "Power4.out" });

    let tl1 = gsap.timeline({
      scrollTrigger: {
        trigger: currentTopRef,
        start: "top 60%",
        // markers: process.env.NODE_ENV === "development" ? true : false
      },
    });

    let tl2 = gsap.timeline({
      scrollTrigger: {
        trigger: currentBottomRef,
        start: "top 60%",
        // markers: process.env.NODE_ENV === "development" ? true : false
      },
    });

    tl1.staggerFrom(currentTopRef, 1, { autoAlpha: 0, y: 40 }, 0.3);

    tl2.staggerFrom(
      currentBottomRef,
      1,
      {
        y: 40,
        autoAlpha: 0,
      },
      0.3
    );

    return () => {};
  }, []);

  return (
    <div className={styles["top"]}>
      <div className={styles["top__container"]}>
        <SingleSection
          left={
            <div className={styles["top__first__left"]}>
              <div
                className={styles["top__first__left__under"]}
                ref={(element) => {
                  topRef.current[0] = element;
                }}
              >
                <Image
                  src={Model}
                  alt="a model"
                  placeholder="blur"
                  quality={100}
                />
              </div>
              <div
                ref={(element) => {
                  topRef.current[1] = element;
                }}
                className={styles["top__first__left__on-top"]}
                data-scroll
                data-scroll-speed="3"
              >
                <Image
                  src={Invitations}
                  alt="an invitation card"
                  placeholder="blur"
                  quality={100}
                />
              </div>
            </div>
          }
          right={
            <div className={styles["top__first__right"]}>
              <div className={styles["top__hidden"]}>
                <div
                  className={styles["top__heading"]}
                  ref={(element) => {
                    topRef.current[2] = element;
                  }}
                >
                  No more stuffing envelopes
                </div>
              </div>

              <div className={styles["top__hidden"]}>
                <div
                  className={styles["top__text"]}
                  ref={(element) => {
                    topRef.current[3] = element;
                  }}
                >
                  Send digital invitations directly to your friends and family
                </div>
              </div>
            </div>
          }
        />

        <SingleSection
          left={
            <div className={styles["top__second__left"]}>
              <div
                className={styles["top__second__left__on-top"]}
                ref={(element) => {
                  bottomRef.current[0] = element;
                }}
              >
                <Image
                  src={MyPlan}
                  alt="my plan list"
                  placeholder="blur"
                  quality={100}
                />
              </div>
            </div>
          }
          right={
            <div className={styles["top__second__right"]}>
              <div className={styles["top__hidden"]}>
                <div
                  className={styles["top__heading"]}
                  ref={(element) => {
                    bottomRef.current[1] = element;
                  }}
                >
                  Stay on track
                </div>
              </div>

              <div className={styles["top__hidden"]}>
                <div
                  className={styles["top__text"]}
                  ref={(element) => {
                    bottomRef.current[2] = element;
                  }}
                >
                  Manage your plans and be fully ready for your big day
                </div>
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
};

export default TopSection;
