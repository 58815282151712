import * as React from "react";
import styles from "./singleSection.module.scss";

type Props = {
  left?: React.ReactNode;
  right?: React.ReactNode;
};

const SingleSection: React.FC<Props> = (props) => {
  let sectionRef = React.useRef<HTMLElement | null>(null);

  return (
    <section className={styles["singleSection"]} ref={sectionRef}>
      {props.left}
      {props.right}
    </section>
  );
};

export default SingleSection;
