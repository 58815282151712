import * as React from "react";
import styles from "./bottom.module.scss";
import SingleSection from "../SingleSection";
import { gsap } from "gsap";
import Image from "next/image";
import Gifts from "../../../../public/assets/images/gifts.png";
import CashGift from "../../../../public/assets/images/cash-gift.png";
import Wishlist from "../../../../public/assets/images/wishlist.png";
import Hurray from "../../../../public/assets/icons/hurray-gold.svg";

type Props = {
  left?: React.ReactNode;
  right?: React.ReactNode;
};

const BottomSection: React.FC<Props> = (props) => {
  let bottomRef = React.useRef<Array<HTMLDivElement | null>>([]);
  let topRef = React.useRef<Array<HTMLDivElement | null>>([]);

  React.useEffect(() => {
    let currentTopRef = topRef.current;
    let currentBottomRef = bottomRef.current;

    gsap.defaults({ ease: "Power4.out" });

    let tl1 = gsap.timeline({
      scrollTrigger: {
        trigger: currentTopRef,
        start: "top 60%",
        // markers: process.env.NODE_ENV === "development" ? true : false
      },
    });

    let tl2 = gsap.timeline({
      scrollTrigger: {
        trigger: currentBottomRef,
        start: "top 60%",
        // markers: process.env.NODE_ENV === "development" ? true : false
      },
    });

    tl1.staggerFrom(currentTopRef, 1, { autoAlpha: 0, y: 40 }, 0.3);

    tl2.staggerFrom(
      currentBottomRef,
      1,
      {
        y: 40,
        autoAlpha: 0,
      },
      0.3
    );

    return () => {};
  }, []);

  return (
    <div className={styles["bottom"]}>
      <div className={styles["bottom__container"]}>
        <SingleSection
          left={
            <div className={styles["bottom__first__left"]}>
              <div className={styles["bottom__hidden"]}>
                <div
                  className={styles["bottom__heading"]}
                  ref={(element) => {
                    topRef.current[0] = element;
                  }}
                >
                  It&apos;s raining cash
                </div>
              </div>

              <div className={styles["bottom__hidden"]}>
                <div
                  className={styles["bottom__text"]}
                  ref={(element) => {
                    topRef.current[1] = element;
                  }}
                >
                  Receive cash gifts directly from your friends and family all
                  over the world
                </div>
              </div>
            </div>
          }
          right={
            <div className={styles["bottom__first__right"]}>
              <div className={styles["bottom__first__right__orange"]} />
              <div
                className={styles["bottom__first__right__under"]}
                ref={(element) => {
                  topRef.current[2] = element;
                }}
              >
                <Image
                  src={Gifts}
                  alt="a collection of gifts"
                  placeholder="blur"
                  quality={100}
                />
              </div>
              <div
                className={styles["bottom__first__right__on-top"]}
                data-scroll
                data-scroll-speed="2"
                ref={(element) => {
                  topRef.current[3] = element;
                }}
              >
                <Image
                  src={CashGift}
                  alt="cash gift"
                  placeholder="blur"
                  quality={100}
                />
              </div>
            </div>
          }
        />

        <SingleSection
          left={
            <div className={styles["bottom__second__left"]}>
              <div className={styles["bottom__hidden"]}>
                <div
                  className={styles["bottom__heading"]}
                  ref={(element) => {
                    bottomRef.current[0] = element;
                  }}
                >
                  Make a wish
                </div>
              </div>

              <div className={styles["bottom__hidden"]}>
                <div
                  className={styles["bottom__text"]}
                  ref={(element) => {
                    bottomRef.current[1] = element;
                  }}
                >
                  Have your guests pay for the items on your specially curated
                  wishlist
                </div>
              </div>
            </div>
          }
          right={
            <div className={styles["bottom__second__right"]}>
              <div
                className={styles["bottom__second__right__under"]}
                ref={(element) => {
                  bottomRef.current[2] = element;
                }}
              >
                <Image
                  src={Wishlist}
                  alt="a wishlist"
                  placeholder="blur"
                  quality={100}
                />
              </div>
              <div
                className={styles["bottom__second__right__on-top"]}
                data-scroll
                data-scroll-speed="2"
                ref={(element) => {
                  bottomRef.current[3] = element;
                }}
              >
                <Hurray />
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
};

export default BottomSection;
