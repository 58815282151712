import * as React from "react";
import styles from "./photo.module.scss";
import Image from "next/image";
import VenueImage from "../../../public/assets/images/venue.png";

type Props = {};

const Photo: React.FC<Props> = (props) => {
  return (
    <section
      className={styles["photo"]}
      id="stick"
      data-scroll-sticky
      data-scroll
      data-scroll-target="#stick"
    >
      <div className={styles["photo__image"]}>
        <Image
          src={VenueImage}
          alt="a wedding venue with people"
          placeholder="blur"
          objectFit="cover"
          layout="fill"
          quality={100}
          priority
        />
      </div>
    </section>
  );
};

export default Photo;
